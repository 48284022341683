import { useEffect, useState } from "react";
import Script from "next/script";

import { getYoutubeVideoId } from "@utils/getYoutubeVideoId";
import PlayIcon from "@assets/svg/play.svg";

import * as Styled from "./styles";
import { VideoPlayerProps } from "./types";

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url,
  width,
  alignment,
  videoSchema: {
    name,
    description,
    thumbnailUrl,
    uploadDate,
    duration,
    embededUrl,
    interactionCount
  } = {}
}) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const videoId = getYoutubeVideoId(url);
  const [thumbnail, setThumbnail] = useState(
    `https://i.ytimg.com/vi/${videoId}/sddefault.jpg`
  );

  useEffect(() => {
    const img = new Image();

    img.onload = () => {
      if (img.width < 640) {
        setThumbnail(
          `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`
        );
      }
    };

    img.src = `https://i.ytimg.com/vi/${videoId}/sddefault.jpg`;
  }, [videoId]);

  return (
    <Styled.Wrapper {...{ width, alignment }}>
      <Script id="video-schema" type="application/ld+json">
        {`{
            "@context": "http://schema.org",
            "@type": "VideoObject",
            "name": "${name}",
            "description": "${description}",
            "thumbnailUrl": "${thumbnailUrl}",
            "uploadDate": "${uploadDate}",
            "duration": "${duration}",
            "embedUrl": "${embededUrl}",
            "interactionCount": "${interactionCount}"
          }
        `}
      </Script>
      <Styled.PlayerContainer>
        <Styled.Player
          aria-label="video player"
          {...{ url }}
          playsInline
          light={thumbnail}
          width="100%"
          height="100%"
          playing={isPlaying}
          muted
          controls
          playIcon={
            <Styled.PlayButton
              aria-label="Play Button"
              size="big"
              icon={PlayIcon}
              onClick={() => setIsPlaying(true)}
            />
          }
        />
      </Styled.PlayerContainer>
    </Styled.Wrapper>
  );
};

export default VideoPlayer;
