import React from "react";
import { storyblokEditable } from "@storyblok/react";

import LazyHydrate from "@components/utils/LazyHydrate";
import VideoPlayer from "@components/molecules/VideoPlayer";

import { VideoPlayerStoryblokProps } from "./types";

const VideoPlayerStoryblok: React.FC<VideoPlayerStoryblokProps> = ({
  blok
}) => {
  const {
    url,
    width,
    alignment,
    name = "",
    description = "",
    thumbnailUrl = "",
    uploadDate = "",
    duration = "",
    embededUrl = "",
    interactionCount = 0
  } = blok;

  const videoSchema = {
    name,
    description,
    thumbnailUrl,
    uploadDate,
    duration,
    embededUrl,
    interactionCount
  };

  const props = {
    url,
    width: width ? +width : undefined,
    alignment,
    videoSchema
  };

  return url ? (
    <LazyHydrate
      useDisplayContents={false}
      noWrapper
      whenVisible
      getEventTarget={() => window}
    >
      <VideoPlayer {...{ ...props, ...storyblokEditable(blok) }} />
    </LazyHydrate>
  ) : null;
};

export default VideoPlayerStoryblok;
