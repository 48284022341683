import dynamic from "next/dynamic";
import styled, { css } from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";
import Button from "@pagepro-monorepo/ui/lib/components/atoms/Button";

import { StyledVideoWrapperProps } from "./types";

const ReactPlayer = dynamic(() => import("react-player"), {
  ssr: false
});

export const PlayButton = styled(Button)``;

export const PlayerContainer = styled.div`
  position: relative;
  padding-top: 56.25%;

  &:hover ${PlayButton} {
    @media ${media.tablet} {
      background-position: 0 100%;
    }
  }
`;

export const Wrapper = styled.div<StyledVideoWrapperProps>`
  width: 100%;

  box-shadow: 0 0.625rem 6.125rem
    ${({ theme }) => theme.colors.greyDropShadow};

  ${({ alignment }) =>
    alignment &&
    css`
      margin: ${alignment};
    `}

  ${({ width }) =>
    width &&
    css`
      max-width: ${width / 16}rem;
    `}
`;

export const Player = styled(ReactPlayer)`
  position: absolute;
  left: 0;
  top: 0;
`;
